/* JavaScript File                                                  */
/* accordion.js                                                   */
/* Modified Sept 21, 2022                                           */


var readmore = (function(document, window) {

	var evt = [

		function(document, window) {

			var toggle = document.querySelectorAll('[data-toggle=readmore]');

			toggle.forEach(function(readmore) {

				var target = readmore.previousElementSibling;
				var more = readmore.getAttribute('data-label').split(',')[0];
				var less = readmore.getAttribute('data-label').split(',')[1];

				readmore.addEventListener('click', function (event) {


					if(!target.classList.contains('show')) {

						target.classList.add('show');
        		target.style.height = "auto";

						var height = target.clientHeight + 'px';

						target.style.height = '0px';
						readmore.innerHTML = less;

						setTimeout(() => {
							target.style.height = height
						}, 0) 

					} else {

						target.style.height = '0px';
						readmore.innerHTML = more;

						target.addEventListener('transitionend', function(){
							target.classList.remove('show');
						}, {once: true});

					}

				}, false);
			});

		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

readmore.init();
