/* JavaScript File                                                  */
/* accordion.js                                                   */
/* Modified Feb 09, 2022                                           */


var accordion = (function(document, window) {

	var evt = [

		function(document, window) {

			var toggle = document.querySelectorAll('[data-toggle=accordion]');
			var backdrop = document.querySelector('.layout-backdrop');

			toggle.forEach(function(accord) {

				var target = accord.nextElementSibling;

				var close = function(){

					var panels = document.querySelectorAll('.' + accord.getAttribute('aria-controls'));

					panels.forEach(function(panel){

						if(panel.classList.contains('open')) {

							panel.style.width = '0px';
	
							panel.addEventListener('transitionend', function(){
								panel.classList.remove('open');
							}, {once: true});
						}
					});

					
				}

				accord.addEventListener('click', function (event) {

					close();

					if(!target.classList.contains('open')) {

						target.classList.add('open');
        		target.style.width = "auto";

						var width = target.clientWidth + 'px';

						target.style.width = '0px';

						setTimeout(() => {
							target.style.width = width
						}, 0) 

						backdrop.setAttribute('aria-hidden', 'false');

					} else {

						target.style.width = '0px';

						target.addEventListener('transitionend', function(){
							target.classList.remove('open');
						}, {once: true});

						backdrop.setAttribute('aria-hidden', 'true');

					}


				}, false);

				backdrop.addEventListener('click', function(){

					close();
					backdrop.setAttribute('aria-hidden', 'true');
				});

			});

		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

accordion.init();
