/* JavaScript File                                                  */
/* accordion.js                                                   */
/* Modified Feb 09, 2022                                           */


var accordion_vertical = (function(document, window) {

	var evt = [

		function(document, window) {

			var toggle = document.querySelectorAll('[data-toggle=accordion-vertical]');

			toggle.forEach(function(accord) {

				var target = accord.nextElementSibling;

				var close = function(){

					var panels = document.querySelectorAll('.' + accord.getAttribute('aria-controls'));

					panels.forEach(function(panel){

						if(panel.classList.contains('open')) {

							panel.style.height = '0px';
	
							panel.addEventListener('transitionend', function(){
								panel.classList.remove('open');
							}, {once: true});
						}
					});

					
				}

				accord.addEventListener('click', function (event) {

					close();

					if(!target.classList.contains('open')) {

						target.classList.add('open');
        		target.style.height = "auto";

						var height = target.clientHeight + 'px';

						target.style.height = '0px';

						setTimeout(() => {
							target.style.height = height
						}, 0) 

					} else {

						target.style.height = '0px';

						target.addEventListener('transitionend', function(){
							target.classList.remove('open');
						}, {once: true});

					}

				}, false);
			});

		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

accordion_vertical.init();
